<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="query" layout="inline" :model="query" @submit.native.prevent @submit="handleQuery">
        <a-form-model-item prop="userName" label="用户">
          <a-input allow-clear v-model="query.userName" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="userPhone" label="手机号">
          <a-input allow-clear v-model="query.userPhone" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="orderNo" label="订单编号">
          <a-input allow-clear v-model="query.orderNo" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="state" label="状态">
          <a-select style="width: 120px;" allow-clear v-model="query.state" placeholder="请选择">
            <a-select-option v-for="item in stateList" :value="item.value" :key="item.value">{{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="operationTime" label="操作时间">
          <a-range-picker v-model="query.operationTime"/>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <div style=''>
      <a-button style='margin-right:32px;' type='primary' :loading='exportLoading'
                @click='handleExport'>导出
      </a-button>
      <a-upload name="file" accept='.xlsx' :beforeUpload="handleImport" :showUploadList='false'>
        <a-button type='primary' :loading="importLoading">导入</a-button>
      </a-upload>
    </div>

    <base-table
      bordered
      :columns="columns"
      :dataSource="data"
      :pagination="page"
      :rowKey="x => x.userXhcdId"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #user="ctx">
        <div class="user-card">
          <img v-if="ctx.headImg" :src="ctx.headImg" alt=""/>
          <p>{{ ctx.userName }}</p>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { capital, order } from '@/api'
import { useExport } from '@/utils/tools'

export default {
  name: 'PageChargeXhcdHistory',
  setup (props, { root }) {
    const getInitQuery = () => ({
      orderNo: '',
      userName: '',
      userPhone: '',
      orderName: '',
      operationTime: [],
      state: undefined,
      shopId: getSession(SHOPID),
    })
    const query = reactive(getInitQuery())

    const importLoading = ref(false)
    const { exporting, exportFn } = useExport(() => order.exportUserAccountXhcd({
      ...query,
      ...(query.operationTime.length ? {
        startTime: query.operationTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endTime: query.operationTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        operationTime: void 0
      } : null)
    }))

    const page = reactive({
      current: 1,
      size: 10,
      total: 0
    })

    const data = ref([])
    const loading = ref(false)

    async function getTableData () {
      loading.value = true
      const res = await capital.getXhcdHistoryPage({
        ...query,
        ...page,
        ...(query.operationTime.length ? {
          startTime: query.operationTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endTime: query.operationTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          operationTime: void 0
        } : null)
      })
      loading.value = false
      if (res.code === '00000') {
        page.total = res.page.total
        data.value = res.data
      }
    }

    onMounted(() => {
      getTableData()
    })

    function handleQuery () {
      page.current = 1
      getTableData()
    }

    function handleReset () {
      Object.assign(query, getInitQuery())
      handleQuery()
    }

    function handleTableChange ({ current }) {
      page.current = current
      getTableData()
    }

    function handleImport (file, fileList) {
      uploadFile(fileList)
      return false
    }

    async function uploadFile (fileList) {
      const formData = new FormData()
      fileList.forEach((x) => {
        formData.append('file', x)
      })
      importLoading.value = true
      let { code, msg } = await order.importUserAccountXhcd(formData)
      importLoading.value = false
      if (code === '00000') {
        root.$message.success(msg || '导入成功')
        handleQuery()
      } else {
        root.$message.error(msg || '上传失败')
      }
    }

    let t
    const columns = [
      {
        title: '用户',
        scopedSlots: {
          customRender: 'user'
        }
      },
      {
        title: '手机号',
        dataIndex: 'userPhone'
      },
      {
        title: '订单编号',
        dataIndex: 'orderNo'
      },
      {
        title: '交易所账号',
        dataIndex: 'account'
      },
      {
        title: '交易所实名',
        dataIndex: 'password',
      },
      {
        title: '兑现货仓单数量',
        dataIndex: 'num',
      },
      {
        title: '备注',
        dataIndex: 'remark'
      },
      {
        title: '状态',
        dataIndex: 'state',
        customRender: x => (t = stateList.find(u => u.value === x)) && t.label
      },
      {
        title: '拒绝理由',
        dataIndex: 'checkCause'
      },
      {
        title: '操作时间',
        dataIndex: 'createTime'
      },
    ]

    const stateList = [
      { value: 0, label: '待审核' },
      { value: 1, label: '审核通过' },
      { value: 2, label: '审核拒绝' },
    ]

    return {
      query,
      data,
      page,
      loading,
      handleQuery,
      handleReset,
      columns,
      handleTableChange,
      getTableData,
      stateList,
      exportLoading: exporting,
      handleExport: exportFn,
      handleImport,
      importLoading,
    }
  }
}
</script>
